import { User } from '@/api/useUsers'

interface IsUniqueCompanyEmailAddress {
  (
    lookupEmail: string,
    selectedUserId: number | undefined,
    userList: Pick<User, 'id' | 'email'>[] | undefined
  ): boolean
}

/**
 * isUniqueCompanyEmailAddress - allows us to check if the email address already exists.
 * Used with @tanstack/vue-query to search the user list stored on the frontend side. Helps improve the UX while submiting
 * a form
 *
 * @param { string } lookupEmail - the input value, we are checking for uniqness
 * @param { number | undefined } selectedUserId - if we are editing an existing user it shouldn't be undefined
 * @param { Pick<User, 'id' | 'email'>[] | undefined } userList - list of user id's along with theit email addresses
 * @returns
 */

export const isUniqueCompanyEmailAddress: IsUniqueCompanyEmailAddress = (
  lookupEmail,
  selectedUserId,
  userList
) => {
  if (!Number.isInteger(selectedUserId)) {
    return !userList?.some(({ email }) => email === lookupEmail)
  }

  return !userList?.some(({ id, email }) => {
    if (selectedUserId === id && email === lookupEmail) {
      // we don't want to find the user we are editing
      return false
    }

    return email === lookupEmail
  })
}
