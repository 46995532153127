import { ValidationRuleWithoutParams } from '@vuelidate/core'
import {
  required,
  email,
  createI18nMessage,
  numeric,
  minLength,
  sameAs,
  helpers,
  MessageProps,
  requiredIf,
  requiredUnless,
  decimal,
} from '@vuelidate/validators'
import { MaybeRefOrGetter, Ref, toValue } from 'vue'
import { useI18n } from 'vue-i18n'
import { User } from '@/api/useUsers'
import { parseNumber } from '@/modules/base/utils/math'
import { isUniqueCompanyEmailAddress } from './validatorIsUniqueCompanyEmail'
import { isComplexPassword } from './validatorPasswordComplexity'

export const useI18nValidators = () => {
  /**
   * Once the number of used validators grow we should create Object.entries loop.
   * Currently it's too heavy.
   */

  const { t } = useI18n()
  const messagePath = ({ $validator }: MessageProps) =>
    `global.validations.${$validator}`
  const withI18nMessage = createI18nMessage({ t, messagePath })
  const { withParams } = helpers

  const withParseNumber = (
    validator: ValidationRuleWithoutParams
  ): ValidationRuleWithoutParams => {
    return {
      $message: validator.$message,
      $validator: (value: string, siblingState: unknown, vm: unknown) =>
        !helpers.req(value) ||
        validator.$validator(parseNumber(value), siblingState, vm),
    }
  }

  return {
    requiredIf: withI18nMessage(requiredIf, { withArguments: true }),
    required: withI18nMessage(required),
    email: withI18nMessage(email),
    numeric: withI18nMessage(withParseNumber(numeric)),
    decimal: withI18nMessage(withParseNumber(decimal)),
    requiredUnless: withI18nMessage(requiredUnless, { withArguments: true }),
    minLength: withI18nMessage(minLength, { withArguments: true }),
    minValue: (min: number) =>
      withI18nMessage(
        withParams(
          { min },
          (value: string) => !helpers.req(value) || parseNumber(value) >= min
        ),
        { withArguments: true }
      ),
    maxValue: (max: number) =>
      withI18nMessage(
        withParams(
          { max },
          (value: string) => !helpers.req(value) || parseNumber(value) <= max
        ),
        { withArguments: true }
      ),
    sameAs: withI18nMessage(sameAs, { withArguments: true }),
    passwordComplexity: withI18nMessage(isComplexPassword),
    emailUnique: (
      userId: MaybeRefOrGetter<number | undefined>,
      userList:
        | MaybeRefOrGetter<undefined>
        | MaybeRefOrGetter<Pick<User, 'id' | 'email'>[]>
    ) =>
      withI18nMessage(
        withParams(
          { selectedUserId: toValue(userId), userEmailList: toValue(userList) },
          (value: string) =>
            isUniqueCompanyEmailAddress(
              value,
              toValue(userId),
              toValue(userList)
            )
        )
      ),
  }
}
